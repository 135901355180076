<template>
  <div id="knowledge-base-page">
      <!-- JUMBOTRON -->
    <div class="knowledge-base-jumbotron">
      <div class="knowledge-base-jumbotron-content lg:p-32 md:p-24 sm:p-16 p-8 rounded-lg mb-base">
        <h1 class="mb-1 text-white">{{ $t('$SearchPanel.Title') }}</h1>
        <p class="text-white">
          {{ $t('$SearchPanel.Subtitle') }}
        </p>
        <vs-input
          v-model="knowledgeBaseSearchQuery"
          :placeholder="$t('$SearchPanel.Placeholder')"
          icon-no-border
          icon-pack="feather"
          icon="icon-search"
          size="large"
          class="w-full mt-6" />
      </div>
    </div>

      <!-- KNOWLEDGE BASE CARDS  -->
    <div class="vx-row">
      <div
        v-for="item in filteredCategories"
        :key="item.id"
        class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base"
        @click="categorySelected(item)">
        <vx-card class="text-center cursor-pointer">
            <img
              :src="item.graphic"
              alt="graphic"
              width="180"
              class="mx-auto mb-4">
            <h4 class="mb-2">{{ item.title.toUpperCase() }}</h4>
            <small>{{ item.description }}</small>
            <div class="knowledge-base-cards-learn-more-button"><span>{{ $t('$KnowledgeBaseCards.LearnMoreButtonText') }}</span></div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  i18n: {
    messages: {
      en: {
        $SearchPanel: {
          Title: 'Dedicated Source Used on Website',
          Subtitle: 'Bonbon sesame snaps lemon drops marshmallow ice cream carrot cake croissant wafer.',
          Placeholder: 'Search Topic or Keyword',
        },
        $KnowledgeBaseCards: {
          LearnMoreButtonText: 'Learn more',
        },
      },
    },
  },
  data() {
    return {
      knowledgeBaseSearchQuery: '',
    };
  },
  computed: {
    ...mapState({
      categories: (state) => state.documentation.data,
    }),
    ...mapGetters({
      categoriesWithRelated: 'documentation/categoriesWithRelated',
    }),
    filteredCategories() {
      return this.categoriesWithRelated.filter(
        (item) => item.title.toLowerCase().includes(this.knowledgeBaseSearchQuery.toLowerCase())
          || item.description.toLowerCase().includes(this.knowledgeBaseSearchQuery.toLowerCase()),
      );
    },
  },
  methods: {
    ...mapMutations({
      setSelectedCategoryMutation: 'documentation/SET_SELECTED_CATEGORY',
    }),
    categorySelected(category) {
      this.setSelectedCategoryMutation(category);
      this.$router.push(this.getCategoryUrl(category)).catch(() => {});
    },
    getCategoryUrl(category) {
      return `/documentation/${category.id}`;
    },
    getQuestionUrl(category, question) {
      const questionEscaped = question.title
        ? question.title.toLowerCase().replaceAll(' ', '-')
        : question.question.id;
      return `/documentation/${category.id}/${questionEscaped}`;
    },
  },
};
</script>

<style lang="scss">

.knowledge-base-jumbotron-content {
    background-image: url('../../assets/img/vuexy/pages/knowledge-base-cover.jpg');
    background-size: cover;
}

.vx-row {

  .vx-col {

    .vx-card {

      height: 100%;

    }

    .vx-card:hover {

      box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.25);

      .knowledge-base-cards-learn-more-button span {

        border-bottom: 1px solid rgba(var(--vs-primary), 0.0);

      }

    }

    .vx-card__collapsible-content {

      height: 100%;

    }

    .vx-card__body {

      height: 100%;
      padding-bottom: 3.5rem;

    }

    .knowledge-base-cards-learn-more-button {

      position: absolute;
      bottom: 1.5rem;
      width: calc(100% - 3rem);

      span {

        color: rgba(var(--vs-primary), 1);
        border-bottom: 1px solid rgba(var(--vs-primary), 0.5);
        transition: .25s;
        font-weight: 600;

      }

    }

  }

}

#knowledge-base-category-page, #knowledge-base-article {

  .vx-row {

    .vx-card {

      height: initial;

    }

    .vx-card__body {

      padding-bottom: 1.5rem;

    }

  }

}

</style>
